var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',_vm._b({attrs:{"rules":_vm.rules,"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
return [_vm._t("label",function(){return [(_vm.label)?_c('label',{class:_vm.labelClasses},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]}),_c('div',{class:[
        { 'input-group': _vm.hasIcon },
        { focused: _vm.focused },
        { 'input-group-alternative': _vm.alternative },
        { 'has-label': _vm.label || _vm.$slots.label },
        _vm.inputGroupClasses
    ]},[(_vm.prependIcon || _vm.$slots.prepend)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._t("prepend",function(){return [_c('i',{class:_vm.prependIcon})]})],2)]):_vm._e(),_vm._t("input",function(){return [_c('multiselect',{class:[{ 'is-valid': valid && validated && _vm.successMessage }, { 'is-invalid': invalid && validated }, _vm.inputClasses],attrs:{"label":"text","track-by":"value","options":_vm.options,"multiple":_vm.multiple,"searchable":true,"loading":_vm.isLoading},on:{"input":_vm.handleInput,"search-change":_vm.fetchOptions},model:{value:(_vm.selectedOptions),callback:function ($$v) {_vm.selectedOptions=$$v},expression:"selectedOptions"}})]},null,_vm.slotData),(_vm.appendIcon || _vm.$slots.append)?_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._t("append",function(){return [_c('i',{class:_vm.appendIcon})]})],2)]):_vm._e(),_vm._t("infoBlock")],2),_vm._t("success",function(){return [(valid && validated && _vm.successMessage)?_c('div',{staticClass:"valid-feedback"},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e()]}),_vm._t("error",function(){return [(errors[0])?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]})]}}],null,true)},'validation-provider',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }