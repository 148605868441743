<template>
    <div>
        <base-header class="pb-6">
            <b-row align-v="center" class="py-4">
                <b-col lg="6" cols="7">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <route-bread-crumb :crumbs="generateBreadCrumb()"></route-bread-crumb>
                    </nav>
                </b-col>
                <b-col lg="6" cols="5" class="text-right"> </b-col>
            </b-row>
        </base-header>
        <b-container fluid class="mt--6">
            <b-row>
                <b-col>
                    <b-card no-body>
                        <b-card-header class="border-0">
                            <b-row>
                                <b-col></b-col>
                                <b-col cols="3"></b-col>
                            </b-row>
                        </b-card-header>

                        <form class="form-width" @submit.prevent="sendMessage">
                            <div class="form-group">
                                <label for="recipient">Recipient:</label>
                                <AsyncMultiselect 
                                :multiple="false"
                                @update:selectedOptions="getValue"
                                v-model="selectedOptions" :api="endpoint" label=""
                                    ></AsyncMultiselect>

                            </div>
                            <div class="form-group">
                                <label for="message">Reason:</label>
                                <input type="text" id="message" v-model="reason" class="form-control" />
                                <br />
                                <label for="message">Message:</label>
                                <textarea id="message" v-model="message" class="form-control"></textarea>
                            </div>
                            <button type="submit" class="btn btn-primary">Add Message (to Queue)</button>
                        </form>
                        <br />
                    </b-card>
                </b-col>
            </b-row>

        </b-container>
    </div>
</template>
  
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as repo from "@/api-repo/notifications";
import { handleError, successMessage } from "@/util/error-handler";
import * as guestRepo from "@/api-repo/guests";
import AsyncMultiselect from "@/components/Inputs/BaseAdvancedSelect.vue";

export default {
    name: 'message-table',
    inject: ["userStore"],
    components: {
        AsyncMultiselect,
        RouteBreadCrumb,
    },
    mounted() {
        this.fetchGuests();
    },
    data() {
        return {
            selectedOptions: [],
            endpoint: process.env.VUE_APP_API_URL + `guest/find`,
            recipient: '', // Selected recipient (guestId)
            message: '', // Message content
            reason: '',
            guests: [] // Array of guests
        };
    },
    methods: {
        getValue(val){
            this.recipient = val.value;
        },
        generateBreadCrumb() {
            return [{ label: "Notifications" }, { label: "Add Message" }];
        },
        async fetchGuests() {
            // Fetch guests from API or other data source
            // and update the guests array
            let guestResp = await guestRepo.getList(this.userStore.state.token)
            this.guests = guestResp.data.data;
        },
        async sendMessage() {
            try {
                const token = this.userStore.state.token;
                const recipient = this.recipient;
                const message = this.message;
                const reason = this.reason;
                let resp = recipient === 'all' ? await repo.createMessages(message, reason, token) : await repo.createMessage(recipient, message, reason, token);
                successMessage("Message(s) queue added successfully");
                console.log(resp)
            } catch (e) {
                handleError(e)
            }
            finally {
                this.message = '';
                this.reason = '';
            }

        }
    },
}
</script>

<style scoped>
/* Add your styling for the component here */
.form-width {
    width: 70%;
    margin: 0 auto;
}
</style>
