<template>
    <validation-provider :rules="rules" :name="name" v-bind="$attrs" v-slot="{ errors, valid, invalid, validated }">

        <slot name="label">
            <label v-if="label" :class="labelClasses">
                {{ label }}
            </label>
        </slot>
        <div :class="[
            { 'input-group': hasIcon },
            { focused: focused },
            { 'input-group-alternative': alternative },
            { 'has-label': label || $slots.label },
            inputGroupClasses
        ]">
            <div v-if="prependIcon || $slots.prepend" class="input-group-prepend">
                <span class="input-group-text">
                    <slot name="prepend">
                        <i :class="prependIcon"></i>
                    </slot>
                </span>
            </div>
         
            <slot name="input" v-bind="slotData">
                <multiselect 
                @input="handleInput"
                label="text" track-by="value" v-model="selectedOptions" :options="options" :multiple="multiple"
                    :searchable="true" :loading="isLoading" @search-change="fetchOptions"
                    :class="[{ 'is-valid': valid && validated && successMessage }, { 'is-invalid': invalid && validated }, inputClasses]">
                </multiselect>

            </slot>
            <div v-if="appendIcon || $slots.append" class="input-group-append">
                <span class="input-group-text">
                    <slot name="append">
                        <i :class="appendIcon"></i>
                    </slot>
                </span>
            </div>
            <slot name="infoBlock"></slot>
        </div>
        <slot name="success">
            <div class="valid-feedback" v-if="valid && validated && successMessage">
                {{ successMessage }}
            </div>
        </slot>
        <slot name="error">
            <div v-if="errors[0]" class="invalid-feedback" style="display: block;">
                {{ errors[0] }}
            </div>
        </slot>
    </validation-provider>
</template>
  
<script>
import { ValidationProvider } from 'vee-validate';
import { Multiselect } from 'vue-multiselect';
import axios from 'axios';

export default {
    name: 'AsyncMultiselect',
    inject: ["userStore"],
    components: {
        ValidationProvider,
        Multiselect,
    },
    inheritAttrs: false,
    props: {
        rules: {
            type: [String, Array, Object],
            description: 'Vee-validate validation rules',
            default: '',
        },
        name: {
            type: String,
            description: 'Input name (used for validation)'
        },
        required: {
            type: Boolean,
            description: "Whether input is required (adds an asterisk *)",
        },

        multiple: {
            type: Boolean,
            description: "Whether multiple or not",
        },
        hasAll: {
            type: Boolean,
            description: "Whether has all options or not",
        },
        rows: {
            type: String,
            description: "Number of rows for text area type",
        },
        group: {
            type: Boolean,
            description: "Whether input is an input group (manual override in special cases)",
        },
        alternative: {
            type: Boolean,
            description: "Whether input is of alternative layout",
        },
        label: {
            type: String,
            description: "Input label (text before input)",
        },
        error: {
            type: String,
            description: "Input error (below input)",
        },
        successMessage: {
            type: String,
            description: "Input success message",
            default: "",
        },
        labelClasses: {
            type: String,
            description: "Input label CSS classes",
            default: "form-control-label",
        },
        inputClasses: {
            type: String,
            description: "Input CSS classes",
        },
        inputGroupClasses: {
            type: String,
            description: "Input group CSS classes",
        },
        value: {
            type: [String, Number, Array],
            description: "Input value",
        },
        type: {
            type: String,
            description: "Input type",
            default: "text",
        },
        appendIcon: {
            type: String,
            description: "Append icon (right)",
        },
        prependIcon: {
            type: String,
            description: "Prepend icon (left)",
        },
        api: {
            type: String,
            required: true,
            description: "API endpoint for fetching options asynchronously",
        },
    },
    data() {
        return {
            options: [{ text: 'All', value: 'all' }],
            isLoading: false,
            selectedOptions:[],
            focused: false,
        };
    },
    computed: {
        hasIcon() {
            const { append, prepend } = this.$slots;
            return (
                append !== undefined ||
                prepend !== undefined ||
                this.appendIcon !== undefined ||
                this.prependIcon !== undefined ||
                this.group
            );
        },
        listeners() {
            return {
                ...this.$listeners,
                input: this.updateValue,
                focus: this.onFocus,
                blur: this.onBlur,
            };
        },
        slotData() {
            return {
                focused: this.focused,
                error: this.error,
                ...this.listeners,
            };
        },
    },
    methods: {
        updateValue(evt) {
            let value = evt.target.value;
            this.$emit('input', this.selectedOptions);
            this.$emit('update:selectedOptions', this.selectedOptions);
        },
        onFocus(evt) {
            this.focused = true;
            this.$emit("focus", evt);
        },
        onBlur(evt) {
            this.focused = false;
            this.$emit("blur", evt);
        },
        fetchOptions(searchQuery) {
            const token = this.userStore.state.token;
            this.isLoading = true;
            axios
                .get(this.api + '/' + searchQuery, {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                })
                .then((response) => {
                    this.options = [{ value: 'all', text: 'All' }, ...response.data.data];
                    this.isLoading = false;
                  
                })
                .catch((error) => {
                    console.error(error);
                    this.isLoading = false;
                });
        },
        handleInput(e){
            this.$emit('update:selectedOptions', this.selectedOptions);
        }
    },
    watch: {
        type(newType) {
            this.multiple = newType === "multiselect";
        }
    },
    created() {
        this.multiple = this.type === "multiselect";
    },
};
</script>

<style></style>
  